@import url('https://fonts.googleapis.com/css2?family=Jura:wght@300..700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');/*Default fonts*/
@import url('https://fonts.googleapis.com/css2?family=Anta&display=swap');

h1 {
    font-family: "Poppins", sans-serif !important;
    font-optical-sizing: auto!important;
    font-size: 68px!important;
    font-weight: 700!important;
    font-style: normal!important;
}


h2 {
    font-family: "Poppins", sans-serif!important;
    font-optical-sizing: auto!important;
    font-size: 48px!important;
    font-weight: 600!important;
    font-style: normal!important;
}


h3 {
    font-family: "Poppins", sans-serif!important;
    font-optical-sizing: auto!important;
    font-size: 36px!important;
    font-weight: 400!important;
    font-style: normal!important;
}

h4 {
    font-family: "Poppins", sans-serif!important;
    font-optical-sizing: auto!important;
    font-size: 28px!important;
    font-weight: 300!important;
    font-style: normal!important;
}



p {
    font-family: "Jura", sans-serif!important;
    font-optical-sizing: auto!important;
    font-weight: 300!important;
    font-style: normal!important;
    font-size: 20px!important;
}



/* Responsive Typography with Media Queries */
@media (max-width: 1024px) { /* Tablets and smaller laptops */
    h1 {
        font-size: 56px !important;
    }
    h2 {
        font-size: 40px !important;
    }
    h3 {
        font-size: 32px !important;
    }
    h4 {
        font-size: 24px !important;
    }
    p {
        font-size: 18px !important;
    }
}

@media (max-width: 768px) { /* Tablets and large phones */
    h1 {
        font-size: 48px !important;
    }
    h2 {
        font-size: 36px !important;
    }
    h3 {
        font-size: 28px !important;
    }
    h4 {
        font-size: 20px !important;
    }
    p {
        font-size: 16px !important;
    }
}

@media (max-width: 480px) { /* Phones */
    h1 {
        font-size: 40px !important;
    }
    h2 {
        font-size: 32px !important;
    }
    h3 {
        font-size: 24px !important;
    }
    h4 {
        font-size: 18px !important;
    }
    p {
        font-size: 14px !important;
    }
}




span {
    font-family: "Jura", sans-serif!important;
    font-optical-sizing: auto!important;
    font-weight: 300!important;
    font-style: normal!important;
    font-size: 20px!important;
}



.anta-regular {
    font-family: "Anta", sans-serif;
    font-weight: 400;
    font-style: normal;
  }

/* <uniquifier>: Use a unique and descriptive class name*/
/* <weight>: Use a value from 300 to 700*/

.jura-paragraph {
    font-family: "Jura", sans-serif;
    font-optical-sizing: auto;
    font-weight: 300;
    font-style: normal;
}



.poppins-thin {
    font-family: "Poppins", sans-serif;
    font-weight: 100;
    font-style: normal;
}

.poppins-thin-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 100;
    font-style: italic;
}

.poppins-extralight {
    font-family: "Poppins", sans-serif;
    font-weight: 200;
    font-style: normal;
}

.poppins-extralight-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 200;
    font-style: italic;
}

.poppins-light {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-style: normal;
}

.poppins-light-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 300;
    font-style: italic;
}

.poppins-regular {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.poppins-regular-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 400;
    font-style: italic;
}

.poppins-medium {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-style: normal;
}

.poppins-medium-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-style: italic;
}

.poppins-semibold {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-style: normal;
}

.poppins-semibold-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    font-style: italic;
}

.poppins-bold {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-style: normal;
}

.poppins-bold-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 700;
    font-style: italic;
}

.poppins-extrabold {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-style: normal;
}

.poppins-extrabold-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 800;
    font-style: italic;
}

.poppins-black {
    font-family: "Poppins", sans-serif;
    font-weight: 900;
    font-style: normal;
}

.poppins-black-italic {
    font-family: "Poppins", sans-serif;
    font-weight: 900;
    font-style: italic;
}
